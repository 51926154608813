export default [
  {
    path: '/reports/customers',
    name: 'ReportCustomers',
    component: () => import('@/views/Admin/Reports/Customers/Index.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Firmalar',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS',
      action: 'read',
    },
  },
  {
    path: '/reports/customers/view/:id_customers',
    name: 'ReportCustomerView',
    component: () => import('@/views/Admin/Reports/Customers/View.vue'),
    meta: {
      pageTitle: 'Raporlar',
      breadcrumb: [
        {
          text: 'Firmalar',
          to: '/reports/customers',
          active: false,
        },
        {
          text: 'Firma Raporu',
          active: true,
        },
      ],
      resource: 'ADMIN_REPORTS',
      action: 'read',
    },
  },
]
