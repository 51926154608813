<template>
  <b-row>
    <b-col cols="12">
      <b-form-group>
        <b-input-group>
          <b-form-datepicker
            v-model="filterParams.sdate"
            placeholder="Başlangıç Tarihi"
            v-bind="{labelNoDateSelected: 'Teslim Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
            @input="getDataList"
          />
          <b-input-group-append is-text>
            -
          </b-input-group-append>
          <b-form-datepicker
            v-model="filterParams.edate"
            placeholder="Bitiş Tarihi"
            v-bind="{labelNoDateSelected: 'Teslim Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
            @input="getDataList"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <b-form-group
        label="Süre Min."
        label-for="min_seconds"
      >
        <v-select
          id="min_seconds"
          v-model="filterParams.min_seconds"
          :options="seconds"
          :reduce="item => item.value"
          placeholder="Süre Min."
          @input="getDataList"
        />
      </b-form-group>
    </b-col>
    <b-col
      cols="12"
      md="6"
    >
      <b-form-group
        label="Süre Max."
        label-for="max_seconds"
      >
        <v-select
          id="max_seconds"
          v-model="filterParams.max_seconds"
          :options="seconds"
          :reduce="item => item.value"
          placeholder="Süre Max."
          @input="getDataList"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BFormDatepicker, BInputGroup, BInputGroupAppend, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'IndexFilters',
  components: {
    BRow,
    BCol,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
  },
  props: {
    getDataList: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      seconds: [
        {
          label: '1 Dakika',
          value: 60,
        },
        {
          label: '5 Dakika',
          value: 300,
        },
        {
          label: '15 Dakika',
          value: 900,
        },
        {
          label: '30 Dakika',
          value: 1800,
        },
        {
          label: '45 Dakika',
          value: 2700,
        },
        {
          label: '1 Saat',
          value: 3600,
        },
      ],
    }
  },
  computed: {
    filterParams() {
      return this.$store.getters['reportCustomers/viewFilterParams']
    },
  },
}
</script>
