<template>
  <b-row>
    <b-col cols="12">
      <b-form-group>
        <b-input-group>
          <b-form-datepicker
            v-model="filterParams.sdate"
            placeholder="Başlangıç Tarihi"
            v-bind="{labelNoDateSelected: 'Teslim Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
            @input="getDataList"
          />
          <b-input-group-append is-text>
            -
          </b-input-group-append>
          <b-form-datepicker
            v-model="filterParams.edate"
            placeholder="Bitiş Tarihi"
            v-bind="{labelNoDateSelected: 'Teslim Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
            @input="getDataList"
          />
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-form-group
        label="Firma Adı"
        label-for="company_name"
      >
        <b-input-group id="company_name">
          <b-form-input
            v-model="filterParams.keyword"
            placeholder="Firma Adı"
            @keydown.enter="getDataList"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="getDataList"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BFormDatepicker, BInputGroup, BInputGroupAppend, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'

export default {
  name: 'IndexFilters',
  components: {
    BRow, BCol, BFormDatepicker, BInputGroup, BInputGroupAppend, BFormGroup, BFormInput, BButton,
  },
  props: {
    getDataList: {
      type: Function,
      required: true,
    },
  },
  computed: {
    filterParams() {
      return this.$store.getters['reportCustomers/filterParams']
    },
  },
}
</script>
