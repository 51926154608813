<template>
  <div>
    <b-card>
      <service-provider />
      <title-text />
      <notes-text />
      <dates-select />
      <status-select />
    </b-card>
    <save-button
      :save-action="submitForm"
      :is-disabled="submitStatus"
    />
  </div>
</template>
<script>
import { BCard } from 'bootstrap-vue'
import ServiceProvider from '@/views/Admin/Domains/elements/ServiceProvider.vue'
import TitleText from '@/views/Admin/Domains/elements/TitleText.vue'
import NotesText from '@/views/Admin/Domains/elements/NotesText.vue'
import DatesSelect from '@/views/Admin/Domains/elements/DatesSelect.vue'
import StatusSelect from '@/views/Admin/Domains/elements/StatusSelect.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    ServiceProvider,
    TitleText,
    NotesText,
    DatesSelect,
    StatusSelect,
    SaveButton,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
    submitStatus: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['domains/dataItem']
    },
  },
}
</script>
