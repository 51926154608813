<template>
  <b-form-group
    label="Yenileme - Bitiş Tarihi"
    label-for="start_date"
  >
    <validation-provider
      #default="{ errors }"
      name="Yenileme - Bitiş Tarihi"
      rules="required"
    >
      <b-input-group>
        <b-form-datepicker
          id="start_date"
          v-model="dataItem.start_date"
          placeholder="Yenileme Tarihi"
          v-bind="{labelNoDateSelected: 'Yenileme Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
        <b-input-group-append is-text>
          -
        </b-input-group-append>
        <b-form-datepicker
          id="end_date"
          v-model="dataItem.end_date"
          placeholder="Bitiş Tarihi"
          v-bind="{labelNoDateSelected: 'Bitiş Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-input-group>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormDatepicker, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'StartDate',
  components: {
    BFormGroup,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['domains/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
