<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col>
          <b-input-group>
            <b-form-input
              v-model="keyword"
              placeholder="Alan Adı, Firma Adı, Sağlayıcı..."
              @keydown.enter="getDataList"
            />
            <b-input-group-append>
              <b-button variant="primary">
                <FeatherIcon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
          cols="12"
          md="auto"
        >
          <b-button
            variant="primary"
            :to="$route.path + '/customer-search'"
          >
            <FeatherIcon icon="PlusIcon" /> Oluştur
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
      fixed
    >
      <template #cell(title)="data">
        <div class="font-weight-bold">
          {{ data.item.title }}
        </div>
        <div class="font-small-3 text-primary">
          {{ data.item.company }}
        </div>
      </template>
      <template #cell(end_date)="data">
        <div
          v-if="moment(data.item.end_date).diff(moment(),'days') <= 30"
          class="text-danger"
        >
          <div class="font-weight-bold">
            {{ moment(data.item.end_date).format('DD.MM.YYYY') }}
          </div>
          <div class="font-weight-bold font-small-3 text-body">
            {{ moment(data.item.end_date).diff(moment(),'days') }} Gün kaldı
          </div>
        </div>
        <div
          v-else-if="moment(data.item.end_date).diff(moment(),'days') > 30 && moment(data.item.end_date).diff(moment(),'days') <= 60"
          class="text-warning"
        >
          <div class="font-weight-bold">
            {{ moment(data.item.end_date).format('DD.MM.YYYY') }}
          </div>
          <div class="font-weight-bold font-small-3 text-body">
            {{ moment(data.item.end_date).diff(moment(),'days') }} Gün kaldı
          </div>
        </div>
        <div
          v-else-if="moment(data.item.end_date).diff(moment(),'days') > 60"
          class="text-success"
        >
          <div class="font-weight-bold">
            {{ moment(data.item.end_date).format('DD.MM.YYYY') }}
          </div>
          <div class="font-weight-bold font-small-3 text-body">
            {{ moment(data.item.end_date).diff(moment(),'days') }} Gün kaldı
          </div>
        </div>
      </template>
      <template #cell(status)="data">
        <FeatherIcon
          :class="data.item.status === '1'? 'text-success' : 'text-warning'"
          :icon="data.item.status === '1'? 'CheckIcon' : 'ClockIcon'"
        />
      </template>
      <template #cell(control)="data">
        <list-buttons
          primary-text="Görüntüle"
          :primary-action="$route.path + '/edit/' + data.item.id"
          :delete-action="removeData"
          :data-id="data.item.id"
        />
      </template>
    </b-table>
    <b-card-footer>
      <div class="d-flex align-items-center justify-content-between">
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
        <div class="font-small-3">
          {{ dataCounts }} adet kayıt bulundu.
        </div>
      </div>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BButton, BCardFooter, BRow, BCol, BFormInput, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import ListButtons from '@/layouts/components/common/ListDropdownButtons.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const tableName = 'domains'
export default {
  name: 'Index',
  components: {
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    ListButtons,
  },
  data() {
    return {
      keyword: null,
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'title',
          label: 'ALAN ADI',
        },
        {
          key: 'service_provider',
          label: 'SAĞLAYICI',
          thClass: 'text-left width-200 text-nowrap',
          tdClass: 'text-left width-200 text-nowrap',
        },
        {
          key: 'end_date',
          label: 'BİTİŞ TARİHİ',
          thClass: 'text-center width-200 text-nowrap',
          tdClass: 'text-center width-200 text-nowrap',
        },
        {
          key: 'status',
          label: 'DURUM',
          thClass: 'text-center width-200 text-nowrap',
          tdClass: 'text-center width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'text-right width-200 text-nowrap',
          tdClass: 'text-right width-200 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.title AS title`,
          `${tableName}.status AS status`,
          `${tableName}.end_date AS end_date`,
          'customers.company AS company',
          'service_providers.title AS service_provider',
        ],
        order_by: ['domains.end_date', 'ASC'],
        limit: this.$store.state.app.perPage,
        start: 0,
        or_like: {},
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['domains/dataList']
    },
    dataCounts() {
      return this.$store.getters['domains/dataCounts']
    },
    saveData() {
      return this.$store.getters['domains/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      if (this.keyword) {
        this.dataQuery.or_like = {
          'domains.title': this.keyword,
          'customers.company': this.keyword,
          'service_providers.title': this.keyword,
        }
      } else {
        delete this.dataQuery.or_like
      }
      this.$store.dispatch('domains/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$store.dispatch('domains/removeData', id)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
