import axiosIns from '@/libs/axios'
import moment from 'moment'

const ROOT_URL = '/Admin/reports/customers'
export default {
  namespaced: true,
  state: {
    dashboard: {
      customer: {
        id: null,
        company: null,
        name: null,
      },
      durations: {
        supports: {
          duration_hours: 0,
          duration_minutes: 0,
          duration_seconds: 0,
          count: 0,
          formatted_duration: '00:00:00',
        },
        tasks: {
          duration_hours: 0,
          duration_minutes: 0,
          duration_seconds: 0,
          count: 0,
          formatted_duration: '00:00:00',
        },
        total: {
          duration_hours: 0,
          duration_minutes: 0,
          duration_seconds: 0,
          count: 0,
          formatted_duration: '00:00:00',
        },
      },
      tasks: [],
      supports: [],
      projects: [],
      users: [],
    },
    dataList: [],
    supportList: [],
    supportListCount: 0,
    taskList: [],
    taskListCount: 0,
    filterParams: {
      keyword: null,
      sdate: moment().startOf('month').format('YYYY-MM-DD'),
      edate: moment().endOf('month').format('YYYY-MM-DD'),
    },
    viewFilterParams: {
      sdate: moment().startOf('month').format('YYYY-MM-DD'),
      edate: moment().endOf('month').format('YYYY-MM-DD'),
      min_seconds: null,
      max_seconds: null,
      modalStatus: false,
    },
    fixedResult: {
      status: null,
      message: null,
    },
  },
  getters: {
    dashboard(state) {
      return state.dashboard
    },
    dataList(state) {
      return state.dataList
    },
    supportList(state) {
      return state.supportList
    },
    supportListCount(state) {
      return state.supportListCount
    },
    taskList(state) {
      return state.taskList
    },
    taskListCount(state) {
      return state.taskListCount
    },
    filterParams(state) {
      return state.filterParams
    },
    viewFilterParams(state) {
      return state.viewFilterParams
    },
    fixedResult(state) {
      return state.fixedResult
    },
  },
  mutations: {
    SET_DASHBOARD(state, data) {
      state.dashboard = data
    },
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_SUPPORT_LIST(state, data) {
      state.supportList = data.data
      state.supportListCount = data.count
    },
    SET_TASK_LIST(state, data) {
      state.taskList = data.data
      state.taskListCount = data.count
    },
    SET_FIXED_RESULT(state, data) {
      state.fixedResult = data
    },
  },
  actions: {
    getDashboard({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(`${ROOT_URL}/dashboard`, data)
        .then(response => {
          commit('SET_DASHBOARD', response.data)
        })
        .catch(error => console.error(error))
    },
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
        })
        .catch(error => console.error(error))
    },
    getSupportList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(`${ROOT_URL}/supportList`, data)
        .then(response => {
          commit('SET_SUPPORT_LIST', response.data)
        })
        .catch(error => console.error(error))
    },
    getTaskList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(`${ROOT_URL}/taskList`, data)
        .then(response => {
          commit('SET_TASK_LIST', response.data)
        })
        .catch(error => console.error(error))
    },
    fixedTime({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post(`${ROOT_URL}/fixedTime`, data)
        .then(response => {
          commit('SET_FIXED_RESULT', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
