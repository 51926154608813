<template>
  <div>
    <b-table
      :items="dashboard.supports"
      :fields="fields"
      striped
      borderless
    >
      <template #cell(task_type)="data">
        <div class="font-weight-bold">
          {{ data.item.task_type }}
        </div>
      </template>
      <template #cell(count)="data">
        <b-badge
          variant="light-warning"
          pill
        >
          {{ data.item.count }}
        </b-badge>
      </template>
      <template #cell(formatted_duration)="data">
        <div class="text-primary">
          {{ data.item.formatted_duration }}
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import { BTable, BBadge } from 'bootstrap-vue'

export default {
  name: 'TaskTypes',
  components: {
    BTable,
    BBadge,
  },
  data() {
    return {
      fields: [
        {
          key: 'task_type',
          label: 'GÖREV TİPİ',
        },
        {
          key: 'count',
          label: 'ADET',
          thClass: 'text-center width-100 text-nowrap',
          tdClass: 'text-center width-100 text-nowrap',
        },
        {
          key: 'formatted_duration',
          label: 'SÜRE',
          thClass: 'text-right width-100 text-nowrap',
          tdClass: 'text-right width-100 text-nowrap',
        },
      ],
    }
  },
  computed: {
    dashboard() {
      return this.$store.getters['reportCustomers/dashboard']
    },
  },
}
</script>
