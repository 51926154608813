<template>
  <b-card>
    <title-text />
    <notes-text />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import TitleText from '@/views/Admin/Defines/Service_providers/elements/TitleText.vue'
import NotesText from '@/views/Admin/Defines/Service_providers/elements/NotesText.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    TitleText,
    NotesText,
  },
}
</script>
