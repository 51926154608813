<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <view-filters :get-data-list="getDataList" />
      </b-card-body>
      <b-table
        :fields="fields"
        :items="taskList"
      >
        <template #cell(title)="data">
          <div class="d-flex align-items-center">
            <b-button
              variant="flat-primary"
              pill
              class="btn-icon"
            >
              <FeatherIcon icon="EyeIcon" />
            </b-button>
            <div class="ml-1">
              <div class="font-small-2 text-primary">
                {{ data.item.project }}
              </div>
              <div class="font-weight-bold">
                {{ data.item.title }}
              </div>
            </div>
          </div>
        </template>
        <template #cell(formatted_duration)="data">
          <div class="font-weight-bolder font-medium-2 text-primary">
            {{ data.item.formatted_duration }}
          </div>
          <div
            v-if="fixedButtons"
            class="my-1"
          >
            <b-button
              variant="outline-warning"
              size="sm"
              @click="fixedTime(data.item.id_task_lines,5)"
            >
              +5
            </b-button>
            <b-button
              variant="outline-warning"
              class="ml-1"
              size="sm"
              @click="fixedTime(data.item.id_task_lines,10)"
            >
              +10
            </b-button>
            <b-button
              variant="outline-warning"
              size="sm"
              class="ml-1"
              @click="fixedTime(data.item.id_task_lines,15)"
            >
              +15
            </b-button>
            <b-button
              variant="outline-warning"
              size="sm"
              class="ml-1"
              @click="fixedTime(data.item.id_task_lines,30)"
            >
              +30
            </b-button>
            <b-button
              variant="outline-warning"
              size="sm"
              class="ml-1"
              @click="fixedTime(data.item.id_task_lines,60)"
            >
              +60
            </b-button>
            <b-button
              variant="outline-warning"
              size="sm"
              class="ml-1"
              @click="fixedTime(data.item.id_task_lines,0)"
            >
              Random
            </b-button>
          </div>
        </template>
      </b-table>
      <b-card-footer>
        <div class="d-flex align-items-center justify-content-between">
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCounts"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
          <div class="font-small-3 text-info">
            Toplam {{ dataCounts }} adet kayıt bulundu.
          </div>
        </div>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BTable, BButton, BCardFooter, BPagination,
} from 'bootstrap-vue'
import ViewFilters from '@/views/Admin/Reports/Customers/components/ViewFilters.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'SupportRequests',
  components: {
    BCard,
    BCardBody,
    BTable,
    BButton,
    BCardFooter,
    BPagination,
    ViewFilters,
  },
  data() {
    return {
      start: 0,
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'title',
          label: 'KONU',
        },
        {
          key: 'formatted_duration',
          label: 'SÜRE',
          thClass: 'text-right width-200 text-nowrap',
          tdClass: 'text-right width-200 text-nowrap',
        },
      ],
    }
  },
  computed: {
    taskList() {
      return this.$store.getters['reportCustomers/taskList']
    },
    dataCounts() {
      return this.$store.getters['reportCustomers/taskListCount']
    },
    viewFilterParams() {
      return this.$store.getters['reportCustomers/viewFilterParams']
    },
    fixedButtons() {
      if (this.$route.query.fixed) {
        return true
      }
      return false
    },
  },
  watch: {
    currentPage(val) {
      this.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('reportCustomers/getTaskList', {
        id_customers: this.$route.params.id_customers,
        sdate: this.viewFilterParams.sdate,
        edate: this.viewFilterParams.edate,
        min_seconds: this.viewFilterParams.min_seconds,
        max_seconds: this.viewFilterParams.max_seconds,
        start: this.start,
        limit: this.$store.state.app.perPage,
      })
    },
    fixedTime(id, minute = 0) {
      this.$store.dispatch('reportCustomers/fixedTime', {
        id_task_lines: id,
        minutes: minute,
        sdate: this.viewFilterParams.sdate,
        edate: this.viewFilterParams.edate,
      })
        .then(response => {
          if (response.status) {
            this.showToast({
              title: 'İşlem Başarılı!',
              icon: 'CheckIcon',
              text: response.message,
              variant: 'success',
            })
            this.getDataList()
          } else {
            this.showToast({
              title: 'İşlem Hatası!',
              icon: 'XIcon',
              text: response.message,
              variant: 'danger',
            })
          }
        })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
