<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <b-card-header class="w-100">
            <b-card-title>{{ dashboard.customer.company }}</b-card-title>
            <div class="d-flex align-items-center">
              <div style="margin-right: 5px">
                <b-button
                  variant="flat-warning"
                  class="btn-icon"
                  pill
                  @click="openModal"
                >
                  <FeatherIcon icon="CalendarIcon" />
                </b-button>
              </div>
              <div class="font-weight-bold text-warning">
                {{ moment(viewFilterParams.sdate).format('DD.MM.YYYY') }} - {{ moment(viewFilterParams.edate).format('DD.MM.YYYY') }}
              </div>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col>
                <div>
                  <div class="font-weight-bold text-primary">
                    Firma Yetkilisi
                  </div>
                  <div class="font-medium-2 font-weight-bolder">
                    {{ dashboard.customer.name }}
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="auto"
                class="border-right bordered"
              >
                <div>
                  <div class="font-weight-bold text-primary">
                    Destek Süre
                  </div>
                  <div class="font-medium-2 font-weight-bolder">
                    {{ dashboard.durations.supports.formatted_duration }}
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="auto"
                class="border-right bordered"
              >
                <div>
                  <div class="font-weight-bold text-primary">
                    Destek Adet
                  </div>
                  <div class="font-medium-2 font-weight-bolder">
                    {{ dashboard.durations.supports.count }}
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="auto"
                class="border-right bordered"
              >
                <div>
                  <div class="font-weight-bold text-primary">
                    Görev Süre
                  </div>
                  <div class="font-medium-2 font-weight-bolder">
                    {{ dashboard.durations.tasks.formatted_duration }}
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="auto"
                class="border-right bordered"
              >
                <div>
                  <div class="font-weight-bold text-primary">
                    Görev Adet
                  </div>
                  <div class="font-medium-2 font-weight-bolder">
                    {{ dashboard.durations.tasks.count }}
                  </div>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="auto"
              >
                <div>
                  <div class="font-weight-bold text-primary">
                    Toplam Süre
                  </div>
                  <div class="font-medium-2 font-weight-bolder">
                    {{ dashboard.durations.total.formatted_duration }}
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        cols="12"
        md="6"
      >
        <b-card
          no-body
        >
          <b-card-header>
            <b-card-title>Görevler</b-card-title>
          </b-card-header>
          <task-types />
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title>Destek Kayıtları</b-card-title>
          </b-card-header>
          <support-types />
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title>Projeler</b-card-title>
          </b-card-header>
          <project-tasks />
        </b-card>
      </b-col>
      <b-col>
        <b-card no-body>
          <b-card-header>
            <b-card-title>Kullanıcılar</b-card-title>
            <div class="font-italic font-small-2">
              En çok talep süresi ile ilk 15 kullanıcı.
            </div>
          </b-card-header>
          <customer-users />
        </b-card>
      </b-col>
    </b-row>
    <modal-date-filter :get-data-list="getData" />
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCol, BRow, BCardBody, BButton,
} from 'bootstrap-vue'
import TaskTypes from '@/views/Admin/Reports/Customers/View/Dashboard/TaskTypes.vue'
import SupportTypes from '@/views/Admin/Reports/Customers/View/Dashboard/SupportTypes.vue'
import ProjectTasks from '@/views/Admin/Reports/Customers/View/Dashboard/ProjectTasks.vue'
import CustomerUsers from '@/views/Admin/Reports/Customers/View/Dashboard/CustomerUsers.vue'
import ModalDateFilter from '@/views/Admin/Reports/Customers/components/ModalDateFilter.vue'

export default {
  name: 'Dashboard',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCol,
    BRow,
    BCardBody,
    BButton,
    TaskTypes,
    SupportTypes,
    ProjectTasks,
    CustomerUsers,
    ModalDateFilter,
  },
  computed: {
    dashboard() {
      return this.$store.getters['reportCustomers/dashboard']
    },
    viewFilterParams() {
      return this.$store.getters['reportCustomers/viewFilterParams']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    openModal() {
      this.viewFilterParams.modalStatus = true
    },
    getData() {
      this.$store.dispatch('reportCustomers/getDashboard', {
        id_customers: this.$route.params.id_customers,
        sdate: this.viewFilterParams.sdate,
        edate: this.viewFilterParams.edate,
      })
    },
  },
}
</script>
