<template>
  <b-form-group
    label="Durum"
    label-for="status"
  >
    <b-form-checkbox
      v-model="dataItem.status"
      switch
      value="1"
    >
      Alan Adı Aktif
    </b-form-checkbox>
  </b-form-group>
</template>
<script>
import { BFormGroup, BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'StatusSelect',
  components: {
    BFormGroup, BFormCheckbox,
  },
  computed: {
    dataItem() {
      return this.$store.getters['domains/dataItem']
    },
  },
}
</script>
