<template>
  <b-modal
    v-model="viewFilterParams.modalStatus"
    centered
    title="Tarih Aralığı"
    hide-footer
    no-close-on-backdrop
    size="lg"
  >
    <b-form-group>
      <b-input-group>
        <b-form-datepicker
          v-model="viewFilterParams.sdate"
          placeholder="Başlangıç Tarihi"
          v-bind="{labelNoDateSelected: 'Teslim Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
        <b-input-group-append is-text>
          -
        </b-input-group-append>
        <b-form-datepicker
          v-model="viewFilterParams.edate"
          placeholder="Bitiş Tarihi"
          v-bind="{labelNoDateSelected: 'Teslim Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-input-group>
    </b-form-group>
    <div class="text-center">
      <b-button
        variant="warning"
        pill
        @click="getReport"
      >
        Raporu Görüntüle
      </b-button>
    </div>
  </b-modal>
</template>
<script>
import {
  BFormDatepicker, BFormGroup, BInputGroup, BInputGroupAppend, BModal, BButton,
} from 'bootstrap-vue'

export default {
  name: 'ModalDateFilter',
  components: {
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BModal,
    BButton,
  },
  props: {
    getDataList: {
      type: Function,
      required: true,
    },
  },
  computed: {
    viewFilterParams() {
      return this.$store.getters['reportCustomers/viewFilterParams']
    },
  },
  methods: {
    getReport() {
      this.getDataList()
      this.viewFilterParams.modalStatus = false
    },
  },
}
</script>
