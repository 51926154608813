export default [
  {
    path: '/admin/domains',
    name: 'Domains',
    component: () => import('@/views/Admin/Domains/Index.vue'),
    meta: {
      pageTitle: 'Alan Adları',
      breadcrumb: [
        {
          text: 'Liste',
          active: true,
        },
      ],
      resource: 'ADMIN_DOMAINS',
      action: 'read',
    },
  },
  {
    path: '/admin/domains/customer-search',
    name: 'DomainCustomerSearch',
    component: () => import('@/views/Admin/Domains/CustomerSearch.vue'),
    meta: {
      pageTitle: 'Alan Adları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/domains',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          active: true,
        },
      ],
      resource: 'ADMIN_DOMAIN_ADD',
      action: 'read',
    },
  },
  {
    path: '/admin/domains/add/:id_customers',
    name: 'DomainAdd',
    component: () => import('@/views/Admin/Domains/Add.vue'),
    meta: {
      pageTitle: 'Alan Adları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/domains',
          active: false,
        },
        {
          text: 'Müşteri Arama',
          to: '/admin/domains/customer-search',
          active: false,
        },
        {
          text: 'Oluştur',
          active: true,
        },
      ],
      resource: 'ADMIN_DOMAIN_ADD',
      action: 'read',
    },
  },
  {
    path: '/admin/domains/edit/:id',
    name: 'DomainEdit',
    component: () => import('@/views/Admin/Domains/Edit.vue'),
    meta: {
      pageTitle: 'Alan Adları',
      breadcrumb: [
        {
          text: 'Liste',
          to: '/admin/domains',
          active: false,
        },
        {
          text: 'Güncelle',
          active: true,
        },
      ],
      resource: 'ADMIN_DOMAIN_EDIT',
      action: 'read',
    },
  },
]
