<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab
        lazy
        active
      >
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Güncel Durum</span>
        </template>
        <dashboard />
      </b-tab>
      <b-tab
        lazy
      >
        <template #title>
          <feather-icon icon="LifeBuoyIcon" />
          <span>Destek Talepleri</span>
        </template>
        <support-list />
      </b-tab>
      <b-tab
        lazy
      >
        <template #title>
          <feather-icon icon="ToggleRightIcon" />
          <span>Görevler</span>
        </template>
        <task-list />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="GithubIcon" />
          <span>Projeler</span>
        </template>
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>Kullanıcılar</span>
        </template>
      </b-tab>
    </b-tabs>

  </div>
</template>
<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import Dashboard from '@/views/Admin/Reports/Customers/View/Dashboard.vue'
import SupportList from '@/views/Admin/Reports/Customers/View/SupportList.vue'
import TaskList from '@/views/Admin/Reports/Customers/View/TaskList.vue'

export default {
  name: 'CustomerView',
  components: {
    BTabs,
    BTab,
    Dashboard,
    SupportList,
    TaskList,
  },
}
</script>
